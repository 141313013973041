import "./App.css";
import React, { useEffect } from 'react';
import { authenticate } from './firebaseConfig';
import ComingSoon from "./pages/Home/ComingSoon.jsx";

function App() {
  useEffect(() => {
    authenticate();
  }, []);
  return <ComingSoon />;
}

export default App;
