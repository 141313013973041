// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwlTW0AYEVbyVAGGxFwRAJdUm-iDaGSHw",
  authDomain: "charles-tobacco.firebaseapp.com",
  projectId: "charles-tobacco",
  storageBucket: "charles-tobacco.appspot.com",
  messagingSenderId: "84643600882",
  appId: "1:84643600882:web:7d4b31045043042be1336b",
  measurementId: "G-GW0KTL5LV1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

// Authentication function
const authenticate = async () => {
    try {
      await signInAnonymously(auth);
      console.log("Signed in anonymously");
    } catch (error) {
      console.error("Error signing in anonymously:", error);
    }
  };

export { db, auth, analytics, authenticate };
