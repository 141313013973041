import * as React from "react";
import logo from "../../assets/svgs/logos/app_logo.svg";
import videoBackground from "../../assets/mp4/bg_smoke.mp4";
import "./styles.css";
import { db } from "../../firebaseConfig";
import { collection, addDoc, query, where, getDocs, doc, setDoc, serverTimestamp } from "firebase/firestore";

function ComingSoon() {
  const [email, setEmail] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email) {
      setStatusMessage(""); // Clear status message
      try {
        // Query to check if the email already exists
        // const q = query(
        //   collection(db, "subscriptions"),
        //   where("email", "==", email)
        // );
        // console.log("Query", q);
        // const querySnapshot = await getDocs(q);
        // console.log("Query Snapshot", querySnapshot);
        // if (!querySnapshot.empty) {
        //   setIsSubmitted(true);
        //   setStatusMessage("This email is already subscribed.");
        //   return;
        // }
        // // Add the new email if it doesn't exist
        // await addDoc(collection(db, "subscriptions"), { email });

        // Use email as the document ID
        const emailDocRef = doc(db, "subscriptions", email);
        // Add the new email or update the existing document
        await setDoc(emailDocRef, { email, timestamp: serverTimestamp() }, { merge: true });

        setIsSubmitted(true);
        setStatusMessage(`Thank you! We'll notify you when we launch.`);
      } catch (error) {
        console.log(error);
        setStatusMessage(error);
        setIsSubmitted(false);
      }
    }
  };

  return (
    <div>
      <video
        autoPlay
        loop
        muted
        className="absolute inset-0 w-full h-full object-cover z-[-20]"
      >
        <source src={videoBackground} type="video/mp4" />
      </video>
      <div className="animated-background"></div>
      <div className="relative h-screen flex items-center justify-center text-white w-full min-h-screen min-w-screen pb-8 pt-20 px-4 sm:px-8 md:px-16">
        <main className="flex flex-col items-center w-full h-full max-w-lg mx-4 font-outfit">
          <img
            loading="lazy"
            src={logo}
            alt="Company logo"
            className="max-w-full w-[250px] md:w-[225px] sm:w-[200px]"
          />
          <div className="flex gap-4 mt-1">
            <div className="animated-text self-stretc text-lg tracking-[5px] text-customGold font-light "></div>
          </div>
          <h1 className="self-stretch mt-14 text-xl sm:text-2xl md:text-3xl font-light uppercase tracking-[19.4px] max-md:mt-10 max-md:max-w-full text-center">
            Coming Soon
          </h1>
          <p className="mt-14 md:mt-8 sm:mt-6 text-xl font-light text-center max-md:mt-4 max-md:max-w-full">
            We're currently working on creating something fantastic.
          </p>
          <p className="mt-2 text-xl font-light text-center">
            We'll be here soon!
          </p>

          {!isSubmitted ? (
            <form
              onSubmit={handleSubmit}
              className="flex gap-2.5 mt-12 max-w-full text-center h-[66px] w-[469px] flex-col sm:flex-row w-full max-md:mt-10"
            >
              <label htmlFor="emailInput" className="sr-only">
                Email Address
              </label>
              <input
                id="emailInput"
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email Address"
                className="flex-grow justify-center items-center px-4 sm:px-8 py-4 sm:py-7 text-sm sm:text-base text-white placeholder-white bg-white bg-opacity-50 rounded-[50px]"
                aria-label="Email Address"
                required
              />
              <button
                type="submit"
                className="flex-shrink-0 px-8 py-4 justify-center items-center text-base font-bold text-black bg-white rounded-[50px] max-md:px-5 hover:bg-gray-200 transition-colors"
              >
                Notify Me
              </button>
            </form>
          ) : (
            <p className="mt-16 text-xl font-light text-center max-md:mt-10">
              {statusMessage}
            </p>
          )}
          <footer className="w-full text-lg font-light text-center text-white fixed bottom-0 pb-4 max-md:mt-10 max-md:max-w-full">
            @2024 All Rights Reserved Charles Tobacco | Powered By Halo 365
          </footer>
        </main>
      </div>
    </div>
  );
}

export default ComingSoon;
